// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: 'Inicio',
    url: '/'
  },
    {
      title: 'Música/Videos',
      url: '/music'
    },
    // {
    //   title: 'Tablaturas',
    //   url: '/tabs'
    // },
    // {
    //   title: 'Eventos Privados',
    //   url: '/events'
    // },
    {
      title: 'Contacto',
      url: '/contact'
    },

  ];
